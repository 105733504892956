import React, { useEffect } from "react";
import { RootState } from "../../store/Store";
import { useDispatch, useSelector } from "react-redux";
import { redirectToUrl } from "../../common/utils/navigation";
import {
  Description,
  Header,
  LinkContainer,
  RelinkButton,
} from "./RelinkFlybuysPageStyles";
import {
  dataLayerFlybuysLinkPageNoThanks,
  dataLayerFlybuysRelinkPage,
} from "../../tracking/tracking";
import {
  FlybuysPrivacyUrl,
  NoThanksLink,
  SmallerDescription,
  StyledLink,
} from "./LinkFlybuysPageStyles";
import { flybuysPreferencesAction } from "./FlybuysAction";
import {
  Heading1Rocket,
  LinkRocket,
  ThemedButtonRocket,
  BodyTextRocket,
} from "ccp-common-ui-components";
import styled from "styled-components";
import useRocketEnabled from "../../common/hooks/useRocketEnabled";
import { rdsMargin } from "@coles/rocket";

const StyledHeading1Rocket = styled(Heading1Rocket)`
  ${rdsMargin.s3.bottom};
`;

const StyledDontAskAgainLinkContainer = styled.div`
  text-align: center;
  ${rdsMargin.s4.top};
`;

const ThemedButtonRocketContainer = styled.div`
  ${rdsMargin.s6.top};
`;

const UpperParagraph = styled(BodyTextRocket)`
  ${rdsMargin.s4.top};
  ${rdsMargin.s4.bottom};
`;

const LowerParagraph = styled(BodyTextRocket)`
  ${rdsMargin.s4.top};
  ${rdsMargin.s4.bottom};
`;

const DescriptionContainer = styled.div``;

function RelinkFlybuysPage() {
  const { linkFlybuysUrl } = useSelector(
    (state: RootState) => state.linkFlybuys
  );

  const dispatch = useDispatch();
  const isRocketEnabled = useRocketEnabled();

  const clientName = useSelector(
    (state: RootState) => state.channel.clientName
  );

  useEffect(() => {
    if (clientName) {
      dataLayerFlybuysRelinkPage(clientName);
    }
  }, [clientName]);

  const relinkMyFlybuysButton = isRocketEnabled ? (
    <ThemedButtonRocketContainer>
      <ThemedButtonRocket
        isFullWidth={true}
        label="Re-link my Flybuys"
        data-testid="relink-my-flybuys-button-rocket"
        onClick={() => {
          redirectToUrl(linkFlybuysUrl);
        }}
        className="sentry-unmask"
      />
    </ThemedButtonRocketContainer>
  ) : (
    <RelinkButton
      isFullWidth={true}
      onClick={() => {
        redirectToUrl(linkFlybuysUrl);
      }}
    >
      Re-link my Flybuys
    </RelinkButton>
  );

  const dontAskMeAgainLink = isRocketEnabled ? (
    <StyledDontAskAgainLinkContainer>
      <LinkRocket
        data-testid="no-thanks-link-rocket"
        label="Don’t ask me again"
        variant="inline"
        size="medium"
        className="fs-unmask"
        onClick={(e) => {
          e.preventDefault();
          dispatch(flybuysPreferencesAction({ skipFlybuys: true }));
          dataLayerFlybuysLinkPageNoThanks();
        }}
      />
    </StyledDontAskAgainLinkContainer>
  ) : (
    <LinkContainer>
      <NoThanksLink
        data-testid="no-thanks-link"
        onClick={(e) => {
          e.preventDefault();

          dispatch(flybuysPreferencesAction({ skipFlybuys: true }));

          dataLayerFlybuysLinkPageNoThanks();
        }}
      >
        Don’t ask me again
      </NoThanksLink>
    </LinkContainer>
  );

  return (
    <>
      {isRocketEnabled ? (
        <DescriptionContainer>
          <StyledHeading1Rocket className="sentry-unmask">
            Re-link your Flybuys account
          </StyledHeading1Rocket>
          <UpperParagraph>
            We've had to make some security enhancements behind the scenes that
            require a quick re-link.
          </UpperParagraph>
          <UpperParagraph>
            To ensure you're getting the best online shopping experience we have
            to offer, please take a few moments to re-link your Flybuys account.
          </UpperParagraph>
          <UpperParagraph>
            Once linked, you will also be able to collect Flybuys points on
            in-store purchases at Coles Supermarkets by scanning your Coles
            barcode.
          </UpperParagraph>
          <LowerParagraph variant="body_100">
            By scanning your Coles barcode, Flybuys will also share your email
            address and personal information with Coles in accordance with your
            email share settings and the{" "}
            <LinkRocket
              data-testid="flybuys-privacy-policy-rocket"
              label="Flybuys privacy policy"
              className="fs-unmask"
              href={FlybuysPrivacyUrl}
              target="_blank"
              size="small"
            />
            {"."}
          </LowerParagraph>
        </DescriptionContainer>
      ) : (
        <DescriptionContainer>
          <Header>Re-link your Flybuys account</Header>
          <Description>
            We've had to make some security enhancements behind the scenes that
            require a quick re-link.
          </Description>
          <Description>
            To ensure you're getting the best online shopping experience we have
            to offer, please take a few moments to re - link your Flybuys
            account.
          </Description>
          <SmallerDescription>
            Once linked, you will also be able to collect Flybuys points on
            in-store purchases at Coles Supermarkets by scanning your Coles
            barcode.
          </SmallerDescription>
          <SmallerDescription>
            By scanning your Coles barcode, Flybuys will also share your email
            address and personal information with Coles in accordance with your
            email share settings and the{" "}
            <StyledLink href={FlybuysPrivacyUrl} target="_blank">
              Flybuys privacy policy
            </StyledLink>
            .
          </SmallerDescription>
        </DescriptionContainer>
      )}

      {relinkMyFlybuysButton}
      {dontAskMeAgainLink}
    </>
  );
}

export default RelinkFlybuysPage;
