export const industryOptions = [
  "Childcare",
  "Schools or further education",
  "Aged care",
  "Disability care",
  "Health or medical",
  "Sports or recreation clubs",
  "Charity or community group",
  "Government",
  "Hospitality",
  "Office-based",
  "Construction or real estate",
  "Retail, wholesale or distribution",
  "Shipping or mining",
  "Other",
];

export interface IndustryOptionsRocket {
  value: any;
  label: string;
}

export const mapIndustryToRocketOptions = (
  industryOptions: any[]
): IndustryOptionsRocket[] => {
  let autoSuggestOptions: IndustryOptionsRocket[] = [];
  autoSuggestOptions.push({
    label: "Select an option",
    value: "",
  });

  let industryOptionsMap: IndustryOptionsRocket[] = industryOptions.map(
    (result) => ({
      label: result,
      value: result,
    })
  );

  autoSuggestOptions = autoSuggestOptions.concat(industryOptionsMap);

  return autoSuggestOptions;
};

export const mobileNumberUrl = "/mobileNumber";

export enum ProfileRoute {
  MfaMobileExempt = "mfamobileexempt",
}
