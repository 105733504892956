import { useSelector } from "react-redux";
import { RootState } from "../../store/Store";
import React from "react";
import { Link, LinkRocket, StyledLink } from "ccp-common-ui-components";

export default function useRocketEnabled() {
  const { featureToggleState } = useSelector(
    (state: RootState) => state.appConfig
  );

  if (!featureToggleState.useRocketEnabledFeatureToggle) return false;
  // For ease of testing, use ?debug_use_rocket=false to override the feature toggle
  const params = new URLSearchParams(window.location.search);
  if (params.get("debug_use_rocket") === "false") return false;
  return true;
}

type SharedLinkProps = {
  "data-testid"?: string;
  href?: string;
  onClick?: (e: React.MouseEvent) => void;
  target?: string;
  label: string;
  size?: "extraSmall" | "small" | "medium" | "large";
  className?: string;
  variant?: "inline" | "primary" | "light" | "emphasis";
};

const DefaultLink = ({ label, size, variant, ...props }: SharedLinkProps) => {
  const isRocketEnabled = useRocketEnabled();
  return isRocketEnabled ? (
    <LinkRocket
      variant={variant || "light"}
      size={size || "small"}
      label={label}
      {...props}
    />
  ) : (
    <Link primary={false} {...props}>
      {label}
    </Link>
  );
};

const PrimaryLink = ({ label, size, ...props }: SharedLinkProps) => {
  const isRocketEnabled = useRocketEnabled();
  return isRocketEnabled ? (
    <LinkRocket
      variant="primary"
      size={size || "small"}
      label={label}
      href={props.href ?? undefined}
      {...props}
    />
  ) : (
    <Link primary {...props}>
      {label}
    </Link>
  );
};

const EmphasisLink = ({ label, ...props }: SharedLinkProps) => {
  const isRocketEnabled = useRocketEnabled();
  return isRocketEnabled ? (
    <LinkRocket
      variant="emphasis"
      size="large"
      label={label}
      href={props.href ?? undefined}
      {...props}
    />
  ) : (
    <StyledLink primary={false} {...props}>
      {label}
    </StyledLink>
  );
};

export { DefaultLink, PrimaryLink, EmphasisLink };
