import {
  InputFieldNew,
  InputFieldRocket,
  InputLabel,
  Link,
  LinkRocket,
  ThemedButtonNew,
  ThemedButtonRocket,
  ErrorText,
  Heading1New,
  Heading1Rocket,
  SecureLoginIconNew,
  SecureLoginIconRocket,
  WarningNotification,
  ThemedMessageRocket,
  BodyTextRocket,
} from "ccp-common-ui-components";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/Store";
import { setChannel, sendOtc, unsetPreVerification } from "./SendOtcSlice";
import {
  clearOtcVerificationErrorMessage,
  SecureLoginInputs,
  verifyOtc,
} from "./OtcVerificationSlice";
import { setShowLoader } from "../loader/LoaderSlice";
import { continueRule } from "../continue/ContinueSlice";
import {
  dataLayerGenericInlineErrorWithArray,
  dataLayerSecureLoginPage,
} from "../../tracking/tracking";
import { validateOneTimeCode } from "../../common/validation/Validation";
import { useWithNav } from "../../utils/withNav";
import { isRouteMfaMobileExempt } from "../../common/commonFunctions";
import useRocketEnabled from "../../common/hooks/useRocketEnabled";
import { rdsMargin } from "@coles/rocket";

function SecureLoginPage() {
  const dispatch = useDispatch();
  const isRocketEnabled = useRocketEnabled();
  const StyledHeading1 = isRocketEnabled ? HeaderRocket : StyledHeading1New;
  const StyledParagraph = isRocketEnabled ? StyledPRocket : StyledP;
  const UnlockIcon = isRocketEnabled ? UnlockIconRocket : UnlockIconNew;
  const StyledDivLinks = isRocketEnabled ? StyledDivRocket : StyledDiv;
  const withNav = useWithNav<SecureLoginInputs>();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SecureLoginInputs>();

  let { otcVerificationErrorMessage } = useSelector(
    (state: RootState) => state.otcVerification
  );

  const { sentTo, maxRetryReached, channel, smsGatewayUnavailable } =
    useSelector((state: RootState) => state.sendOtc);

  const { route } = useSelector((state: RootState) => state.route);

  const [isMaxRetriesNotificationOpen, setIsMaxRetriesNotificationOpen] =
    useState(maxRetryReached);

  useEffect(() => {
    maxRetryReached
      ? setIsMaxRetriesNotificationOpen(true)
      : setIsMaxRetriesNotificationOpen(false);
  }, [maxRetryReached]);

  useEffect(() => {
    if (smsGatewayUnavailable || isRouteMfaMobileExempt(route)) {
      switchToEmail();
    } else {
      switchToSms();
    }
    dispatch(setShowLoader(false));
    dispatch(unsetPreVerification());
  }, [dispatch]);

  const clientName = useSelector(
    (state: RootState) => state.channel.clientName
  );

  useEffect(() => {
    if (clientName) {
      dataLayerSecureLoginPage(clientName);
    }
  }, [clientName]);

  const onSubmit = (formData: SecureLoginInputs) => {
    dispatch(verifyOtc(withNav(formData)));
  };

  const onSendAgain = () => {
    dispatch(clearOtcVerificationErrorMessage());
    clearErrorMessage();
    dispatch(sendOtc(withNav()));
  };

  const hasError = (): boolean => {
    return !!errors?.oneTimeCode?.message || !!otcVerificationErrorMessage;
  };

  const getErrorMessage = (): string => {
    if (errors?.oneTimeCode?.message) {
      return errors.oneTimeCode.message;
    } else if (otcVerificationErrorMessage) {
      return otcVerificationErrorMessage;
    }
    return "";
  };

  const clearErrorMessage = () => {
    if (errors?.oneTimeCode) {
      errors.oneTimeCode.message = "";
    }
    if (otcVerificationErrorMessage) {
      otcVerificationErrorMessage = "";
    }
  };

  const [switchLinkText, setSwitchLinkText] = useState("");

  function switchToEmail() {
    dispatch(setChannel("email"));
    setSwitchLinkText("send code to my mobile");
  }

  function switchToSms() {
    dispatch(setChannel("sms"));
    setSwitchLinkText("email code");
  }

  function switchChannel() {
    if (channel === "sms") {
      switchToEmail();
    } else {
      switchToSms();
    }
  }

  const otcFieldRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (otcFieldRef.current) {
      otcFieldRef.current.focus();
    }
  }, [hasError]);

  useEffect(() => {
    if (hasError()) {
      let errorMessage: string[] = [getErrorMessage()];
      dataLayerGenericInlineErrorWithArray(errorMessage);
    }
  }, [hasError]);
  const registerOptions = register("oneTimeCode", {
    validate: validateOneTimeCode,
    onChange: () => {
      if (otcVerificationErrorMessage) {
        dispatch(clearOtcVerificationErrorMessage());
      }
    },
  });
  const ErrorTextStyled = isRocketEnabled ? null : (
    <StyledErrorText id="one-time-code-error" data-testid="one-time-code-error">
      {getErrorMessage()}
    </StyledErrorText>
  );
  const EmailCode = isRocketEnabled ? (
    <LinkRocket
      id="switch-channel"
      data-testid="switch-channel"
      label={switchLinkText}
      variant="primary"
      onClick={(e) => {
        e.preventDefault();
        switchChannel();
        onSendAgain();
      }}
      className="fs-unmask"
    />
  ) : (
    <Link
      id="switch-channel"
      data-testid="switch-channel"
      primary
      href="/"
      onClick={(e) => {
        e.preventDefault();
        switchChannel();
        onSendAgain();
      }}
    >
      {switchLinkText}
    </Link>
  );
  const SendAgainLink = isRocketEnabled ? (
    <LinkRocket
      id="send-again"
      data-testid="send-again"
      label={
        channel === "sms" ? "Resend code to my mobile" : "Re-email me the code"
      }
      variant="primary"
      onClick={(e) => {
        e.preventDefault();
        onSendAgain();
      }}
      className="fs-unmask"
    />
  ) : (
    <Link
      id="send-again"
      data-testid="send-again"
      primary
      href="/"
      onClick={(e) => {
        e.preventDefault();
        onSendAgain();
      }}
    >
      {channel === "sms" ? "Resend code to my mobile" : "Re-email me the code"}
    </Link>
  );

  const newOtcInputField = isRocketEnabled ? (
    <InputFieldRocket
      id="oneTimeCode"
      type="tel"
      aria-invalid={hasError()}
      aria-describedby="one-time-code-error"
      aria-required="true"
      autoComplete="off"
      data-testid="oneTimeCode"
      hasError={hasError()}
      maxLength={6}
      errorText={getErrorMessage()}
      labelText="One-time code"
      {...registerOptions}
      ref={(e) => {
        otcFieldRef.current = e;
        registerOptions.ref(e);
      }}
    />
  ) : (
    <InputFieldNew
      id="oneTimeCode"
      data-testid="oneTimeCode"
      type="tel"
      autoComplete="off"
      hasError={hasError()}
      maxLength={6}
      aria-describedby="one-time-code-error"
      aria-invalid={hasError()}
      aria-required="true"
      {...registerOptions}
      ref={(e) => {
        otcFieldRef.current = e;
        registerOptions.ref(e);
      }}
    />
  );

  return (
    <MfaDiv>
      <form
        id="secure-login-form"
        onSubmit={handleSubmit(onSubmit)}
        data-testid="secure-login-form"
        noValidate
      >
        <StyledHeading1 className="sentry-unmask">
          {" "}
          <UnlockIcon />
          Secure log in
        </StyledHeading1>
        <StyledParagraph>
          Enter the one-time code we sent to{" "}
          <MobileNumberText>{sentTo}</MobileNumberText>
        </StyledParagraph>
        {newOtcInputField}
        {hasError() && isRocketEnabled && (
          <StyledHideableDiv> </StyledHideableDiv>
        )}
        {hasError() && ErrorTextStyled}

        <StyledDivLinks data-testid="send-again-text">
          {SendAgainLink}{" "}
          {smsGatewayUnavailable || isRouteMfaMobileExempt(route) ? (
            <></>
          ) : (
            <>or {EmailCode}</>
          )}
        </StyledDivLinks>
        {isRocketEnabled ? (
          <ThemedMessageRocket
            data-testid="max-retry-reached-error"
            type="warning"
            title=""
            hasIcon
            isHidden={!isMaxRetriesNotificationOpen}
            text="You've reached the maximum limit for one-time codes sent. Wait 5 minutes to request a new code."
            onClose={() => {
              setIsMaxRetriesNotificationOpen(false);
            }}
            isFullWidth
          />
        ) : (
          <WarningNotification
            data-testid="max-retry-reached-error"
            text="You've reached the maximum limit for one-time codes sent. Wait 5 minutes to request a new code."
            isOpen={isMaxRetriesNotificationOpen}
            closeHandler={() => {
              setIsMaxRetriesNotificationOpen(false);
            }}
          />
        )}

        <StyledCenteredDiv>
          {isRocketEnabled ? (
            <ThemedButtonRocket
              isFullWidth
              label="Continue"
              data-testid="continue-button-rocket"
              className="sentry-unmask"
            />
          ) : (
            <StyledButtonNew type="submit">Continue</StyledButtonNew>
          )}
        </StyledCenteredDiv>
        <StyledCenteredDiv>
          {isRocketEnabled ? (
            <LinkRocket
              id="back-login"
              data-testid="back-login"
              label="Back"
              variant="emphasis"
              size="large"
              onClick={(e) => {
                e.preventDefault();
                dispatch(continueRule());
              }}
              className="fs-unmask"
            />
          ) : (
            <StyledLink
              id="back-login"
              data-testid="back-login"
              onClick={(e) => {
                e.preventDefault();
                dispatch(continueRule());
              }}
            >
              Back
            </StyledLink>
          )}
        </StyledCenteredDiv>
      </form>
    </MfaDiv>
  );
}

export default SecureLoginPage;

const StyledLink = styled(Link)`
  margin-top: 16px;
  font-weight: 600;
  font-size: 18px;
  color: #212121;
`;

const StyledP = styled.p`
  margin-top: 8px;
  margin-bottom: 24px;
`;
const StyledPRocket = styled(BodyTextRocket).attrs({
  variant: "body_300",
})`
  ${rdsMargin.s2.top};
  ${rdsMargin.s6.bottom};
`;

const StyledDiv = styled.div`
  margin-top: 8px;
  margin-bottom: 24px;
`;

const StyledDivRocket = styled.div`
  ${rdsMargin.s6.bottom};
`;

const StyledCenteredDiv = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const StyledButtonNew = styled(ThemedButtonNew)`
  width: 100%;
`;

const StyledErrorText = styled(ErrorText)`
  margin-bottom: 20px;
`;
const StyledHideableDiv = styled.div`
  ${rdsMargin.s4.bottom}
`;

const MobileNumberText = styled.span`
  font-weight: bold;
`;

const MfaDiv = styled.div`
  padding-bottom: 35px;
`;

const UnlockIconNew = styled(SecureLoginIconNew)`
  margin-right: 8px;
`;
const UnlockIconRocket = styled(SecureLoginIconRocket)`
  ${rdsMargin.s2.right}
`;

const StyledHeading1New = styled(Heading1New)`
  margin-bottom: 8px;
`;
const HeaderRocket = styled(Heading1Rocket)`
  ${rdsMargin.s2.bottom};
`;
