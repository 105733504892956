import React from "react";
import { CCPTheme, ColesClientName } from "ccp-common-ui-components";
import useRocketEnabled from "./common/hooks/useRocketEnabled";

interface CCPThemeRocketAwareProps {
  children: React.ReactNode;
  clientName: ColesClientName;
}

export const CCPThemeRocketAware: React.FC<CCPThemeRocketAwareProps> = ({
  children,
  clientName,
}) => {
  const isRocketEnabled = useRocketEnabled();

  return (
    <CCPTheme clientName={clientName} withRocket={isRocketEnabled}>
      {children}
    </CCPTheme>
  );
};
