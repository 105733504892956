import {
  InputFieldNew,
  InputLabel,
  ThemedButtonNew,
  Heading1New,
  DropdownInputField,
  InputFieldRocket,
  DropdownInputFieldRocket,
  ThemedButtonRocket,
  Heading1Rocket,
} from "ccp-common-ui-components";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useForm, ValidateResult } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/Store";
import { setShowLoader } from "../loader/LoaderSlice";
import {
  validateBusinessName,
  validateAbn,
  validateIndustry,
} from "../../common/validation/Validation";
import {
  industryOptions,
  mapIndustryToRocketOptions,
} from "../../common/constants";
import { postBusinessAttributes } from "./BusinessAttributesSlice";
import {
  dataLayerBusinessAttributesPage,
  dataLayerGenericInlineErrorWithArray,
} from "../../tracking/tracking";
import { useWithNav } from "../../utils/withNav";
import useRocketEnabled from "../../common/hooks/useRocketEnabled";
import { rdsMargin } from "@coles/rocket";

export interface BusinessAttributesInputs {
  businessName: string;
  industryType: string;
  abn: string;
}

function BusinessAttributesPage() {
  const withNav = useWithNav<BusinessAttributesInputs>();
  const dispatch = useDispatch();
  const isRocketEnabled = useRocketEnabled();

  const [businessNameErrorMessage, setBusinessNameErrorMessage] =
    useState<string>("");
  const [abnErrorMessage, setAbnErrorMessage] = useState<string>("");
  const [industryTypeErrorMessage, setIndustryTypeErrorMessage] =
    useState<string>("");
  const [industryOptionsRocketInput, setIndustryOptionsRocketInput] =
    useState<string>("");

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<BusinessAttributesInputs>();

  const clientName = useSelector(
    (state: RootState) => state.channel.clientName
  );

  useEffect(() => {
    dispatch(setShowLoader(false));
  }, [dispatch]);

  useEffect(() => {
    if (clientName) {
      dataLayerBusinessAttributesPage(clientName);
    }
  }, [clientName]);

  const hasError = (): boolean => {
    return (
      !!businessNameErrorMessage ||
      !!abnErrorMessage ||
      !!industryTypeErrorMessage
    );
  };

  const getErrorMessages = (): string[] => {
    let errorInlineMessage: string[] = [];

    if (!!businessNameErrorMessage) {
      errorInlineMessage.push(businessNameErrorMessage);
    }

    if (!!abnErrorMessage) {
      errorInlineMessage.push(abnErrorMessage);
    }

    if (!!industryTypeErrorMessage) {
      errorInlineMessage.push(industryTypeErrorMessage);
    }

    return errorInlineMessage;
  };

  useEffect(() => {
    if (hasError()) {
      let errorMessageArray = getErrorMessages();
      dataLayerGenericInlineErrorWithArray(errorMessageArray);
    }
  }, [hasError]);

  const formSubmissionHandler = (data: BusinessAttributesInputs) => {
    dispatch(postBusinessAttributes(withNav(data)));
  };

  const validateBusinessNameInput = (value: string): ValidateResult => {
    if (!value) {
      setError("businessName", {
        type: "custom",
        message: "Enter your registered business name.",
      });
      setBusinessNameErrorMessage("Enter your registered business name.");
      return false;
    }

    if (value && !validateBusinessName(value)) {
      setError("businessName", {
        type: "custom",
        message:
          "The name you entered is invalid. Please use only letters, numbers and spaces.",
      });
      setBusinessNameErrorMessage("The name you entered is invalid.");
      return false;
    }

    setBusinessNameErrorMessage("");
    clearErrors("businessName");
    return true;
  };

  const validateAbnInput = (value?: string): ValidateResult => {
    if (value && value.length && value.length > 0 && !validateAbn(value)) {
      setError("businessName", {
        type: "abn",
        message: "ABN must be a valid Australian ABN.",
      });
      setAbnErrorMessage("ABN must be a valid Australian ABN.");
      return false;
    }

    clearErrors("abn");
    setAbnErrorMessage("");
    return true;
  };

  const validateIndustryInput = (value: string): ValidateResult => {
    if (!value || (value && !validateIndustry(value))) {
      setError("industryType", {
        type: "custom",
        message: "Select an industry.",
      });
      setIndustryTypeErrorMessage("Select an industry.");
      return false;
    }

    clearErrors("industryType");
    setIndustryTypeErrorMessage("");
    return true;
  };

  const clearBusinessNameErrorMessage = () => {
    if (errors?.businessName) {
      errors.businessName.message = "";
    }
    setBusinessNameErrorMessage("");
  };

  const clearAbnErrorMessage = () => {
    if (errors?.abn) {
      errors.abn.message = "";
    }
    setAbnErrorMessage("");
  };

  const clearIndustryErrorMessage = () => {
    if (errors?.industryType) {
      errors.industryType.message = "";
    }
    setIndustryTypeErrorMessage("");
  };

  const onChangeHandlerIndustryOptionsRocket = (
    event: React.SyntheticEvent
  ) => {
    setIndustryOptionsRocketInput(event.target.value);
    if (validateIndustryInput(event.target.value)) {
      clearIndustryErrorMessage();
    }
  };

  const businessNameInputField = (
    <StyledDiv>
      <InputFieldNew
        id="business-name-input-field"
        data-testid="business-name-input-field"
        type="text"
        autoComplete="off"
        hasError={!!businessNameErrorMessage}
        errorText={businessNameErrorMessage}
        aria-describedby="business-name-input"
        aria-invalid={!!businessNameErrorMessage}
        aria-required="true"
        {...register("businessName", {
          validate: validateBusinessNameInput,
          onChange: () => {
            clearBusinessNameErrorMessage();
          },
        })}
      />
    </StyledDiv>
  );

  const businessNameInputFieldRocket = (
    <StyledDivRocket>
      <InputFieldRocket
        id="business-name-input-field"
        data-testid="business-name-input-field-rocket"
        type="text"
        hasError={!!businessNameErrorMessage}
        errorText={businessNameErrorMessage}
        aria-describedby="business-name-input-rocket"
        aria-invalid={!!businessNameErrorMessage}
        aria-required="true"
        {...register("businessName", {
          validate: validateBusinessNameInput,
          onChange: () => {
            clearBusinessNameErrorMessage();
          },
        })}
      />
    </StyledDivRocket>
  );

  const businessNameInput = isRocketEnabled
    ? businessNameInputFieldRocket
    : businessNameInputField;

  const abnInputField = (
    <StyledDiv>
      <InputFieldNew
        id="abn-input-field"
        data-testid="abn-input-field"
        type="tel"
        autoComplete="off"
        {...register("abn", {
          validate: validateAbnInput,
          onChange: () => {
            clearAbnErrorMessage();
          },
        })}
        hasError={!!abnErrorMessage}
        errorText={abnErrorMessage}
        aria-describedby="abn-input-field"
        aria-invalid={!!abnErrorMessage}
        aria-required="true"
      />
    </StyledDiv>
  );

  const abnInputFieldRocket = (
    <StyledDivRocket>
      <InputFieldRocket
        id="abn-input-field"
        data-testid="abn-input-field-rocket"
        type="tel"
        {...register("abn", {
          validate: validateAbnInput,
          onChange: () => {
            clearAbnErrorMessage();
          },
        })}
        hasError={!!abnErrorMessage}
        errorText={abnErrorMessage}
        aria-describedby="abn-input-field-rocket"
        aria-invalid={!!abnErrorMessage}
        aria-required="true"
      />
    </StyledDivRocket>
  );

  const abnInput = isRocketEnabled ? abnInputFieldRocket : abnInputField;

  const industryInputFieldStyledInputLabel = isRocketEnabled ? (
    <></>
  ) : (
    <StyledInputLabel htmlFor="industry-input-field">
      Industry or workplace type
    </StyledInputLabel>
  );

  const industryInputField = (
    <StyledDiv>
      <DropdownInputField
        id="industry-input-field"
        data-testid="industry-input-field"
        autoComplete="off"
        hasError={!!industryTypeErrorMessage}
        errorText={industryTypeErrorMessage}
        descriptionText="To help us understand which products and services might be useful for your business."
        aria-describedby="industry-input-field"
        aria-invalid={!!industryTypeErrorMessage}
        aria-required="true"
        options={industryOptions}
        placeholder="Select an option"
        {...register("industryType", {
          validate: validateIndustryInput,
          onChange: () => {
            clearIndustryErrorMessage();
          },
        })}
      />
    </StyledDiv>
  );

  const industryInputFieldRocket = (
    <DropdownInputFieldRocket
      inputLabel="Industry or workplace type"
      id="industry-input-field"
      data-testid="industry-input-field-rocket"
      dataTestidForOptions="select-options"
      hasError={!!industryTypeErrorMessage}
      errorText={industryTypeErrorMessage}
      descriptionText="To help us understand which products and services might be useful for your business."
      aria-describedby="industry-input-field-rocket"
      aria-invalid={!!industryTypeErrorMessage}
      aria-required="true"
      options={mapIndustryToRocketOptions(industryOptions)}
      minWidth="300px"
      value={industryOptionsRocketInput}
      {...register("industryType", {
        validate: validateIndustryInput,
        onChange: (e) => {
          onChangeHandlerIndustryOptionsRocket(e);
        },
      })}
    />
  );

  const industryInput = isRocketEnabled
    ? industryInputFieldRocket
    : industryInputField;

  const continueButton = isRocketEnabled ? (
    <ThemedButtonRocketContainer>
      <ThemedButtonRocket
        isFullWidth
        label="Continue"
        data-testid="continue-button-rocket"
        className="sentry-unmask"
      />
    </ThemedButtonRocketContainer>
  ) : (
    <StyledCenteredDiv>
      <StyledButtonNew type="submit">Continue</StyledButtonNew>
    </StyledCenteredDiv>
  );

  const styledHeading = isRocketEnabled ? (
    <StyledHeading1Rocket className="sentry-unmask">
      Tell us about your business
    </StyledHeading1Rocket>
  ) : (
    <StyledHeading1New>Tell us about your business</StyledHeading1New>
  );

  return (
    <BusinessDiv>
      <form
        id="business-attributes-form"
        onSubmit={handleSubmit(formSubmissionHandler)}
        data-testid="business-attributes-form"
        noValidate
      >
        {styledHeading}
        <StyledInputLabel htmlFor="business-name-input-field">
          Registered business name
        </StyledInputLabel>
        {businessNameInput}

        <StyledInputLabel htmlFor="abn-input-field">{`ABN (optional)`}</StyledInputLabel>
        {abnInput}

        {industryInputFieldStyledInputLabel}
        {industryInput}

        {continueButton}
      </form>
    </BusinessDiv>
  );
}

export default BusinessAttributesPage;

const StyledInputLabel = styled(InputLabel)`
  font-size: 16px;
`;

const StyledDiv = styled.div`
  margin-top: 8px;
  margin-bottom: 24px;
  font-size: 16px;
`;

const StyledCenteredDiv = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const StyledButtonNew = styled(ThemedButtonNew)`
  width: 100%;
`;

const BusinessDiv = styled.div`
  padding-bottom: 35px;
`;

const StyledHeading1New = styled(Heading1New)`
  margin-bottom: 24px;
`;

const StyledDivRocket = styled.div`
  ${rdsMargin.s2.bottom};
`;

const StyledHeading1Rocket = styled(Heading1Rocket)`
  ${rdsMargin.s6.bottom};
`;

const ThemedButtonRocketContainer = styled.div`
  ${rdsMargin.s6.top};
`;
