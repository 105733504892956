import { getMetaTagContent } from "./ConfigurationHelpers";

const localDevelopmentFeatureToggleConfig: FeatureToggleConfig = {
  flybuysFeatureToggle: true,
  mfaMandatoryFeatureToggle: true,
  useRocketEnabledFeatureToggle: true,
};

interface FeatureToggleConfig {
  flybuysFeatureToggle: boolean;
  mfaMandatoryFeatureToggle: boolean;
  useRocketEnabledFeatureToggle: boolean;
}

interface SentryConfig {
  environment: string;
}

export interface Configuration {
  bffBaseUrl: string;
  appInsightsInstrumentationKey: string;
  auth0Domain: string;
  profileManagementUiClientId: string;
  featureToggleConfig: FeatureToggleConfig;
  originLoginUrl: string;
  sentryConfig?: SentryConfig;
}

let configuration: Configuration;
if (process.env.NODE_ENV !== "production") {
  configuration = {
    bffBaseUrl: "http://localhost:5001",
    appInsightsInstrumentationKey: "65a34fa3-4322-461f-bc26-0164b273d4ed",
    auth0Domain: "http://localhost:5001/auth0",
    profileManagementUiClientId: "Bu4KxbdnbYrVygS8kChb1gaFtS8cQ3Rd",
    featureToggleConfig: localDevelopmentFeatureToggleConfig,
    originLoginUrl: "http://localhost:3001/?token=token&state=state",
  };
} else {
  const flybuysFeatureToggle =
    getMetaTagContent("flybuys-feature-toggle") === "true";

  const originLoginUrl = getMetaTagContent("origin-login-url");

  const mfaMandatoryFeatureToggle =
    getMetaTagContent("mfa-mandatory-feature-toggle") === "true";

  const useRocketEnabledFeatureToggle =
    getMetaTagContent("use-rocket-enabled-feature-toggle") === "true";

  const featureToggleConfig: FeatureToggleConfig = {
    flybuysFeatureToggle,
    mfaMandatoryFeatureToggle,
    useRocketEnabledFeatureToggle,
  };

  const sentryConfig: SentryConfig = {
    environment: getMetaTagContent("sentry-environment"),
  };

  configuration = {
    bffBaseUrl: getMetaTagContent("bff-base-url"),
    appInsightsInstrumentationKey: getMetaTagContent(
      "app-insights-instrumentation-key"
    ),
    auth0Domain: getMetaTagContent("auth0-domain"),
    profileManagementUiClientId: getMetaTagContent(
      "profile-management-ui-client-id"
    ),
    featureToggleConfig: featureToggleConfig,
    originLoginUrl: originLoginUrl,
    sentryConfig: sentryConfig,
  };
}

export default configuration;
