import React from "react";
import styled from "styled-components";
import {
  LoaderNew,
  HideableDiv,
  ThemedHeaderNew,
} from "ccp-common-ui-components";
import PrivacyPolicyLink from "./features/user-acceptances/PrivacyPolicyLink";
import useRocketEnabled from "./common/hooks/useRocketEnabled";

export interface UserStepContainerProps {
  children?: any;
  showLoader?: boolean;
  screenReaderLoadingMessage?: string;
}

export const ThemedHeaderMod = styled(ThemedHeaderNew)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

const UserStepRootDiv = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  align-content: center;
`;

const UserStepFeature = styled.div<{ isRocketEnabled: boolean }>`
  margin: 24px auto 0 auto;
  padding: 0 48px;
  flex: 1 0 auto;
  max-width: ${({ isRocketEnabled }) => (isRocketEnabled ? "446px" : "350px")};

  @media (max-width: ${(props) => props.theme.mobileBreakpoint}) {
    border: none;
    margin: 24px auto 0 auto;
  }
  position: relative;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  font-size: 14px;
  width: 100%;
  flex-grow: 0;
  height: 48px;
  @media (max-width: ${(props) => props.theme.mobileBreakpoint}) {
    border-top: 1px solid #e5e5e5;
    justify-content: flex-start;
  }
`;

const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const UserStepContainer = (props: UserStepContainerProps) => {
  const showLoader = props.showLoader ? props.showLoader : false;
  const isRocketEnabled = useRocketEnabled();

  return (
    <UserStepRootDiv>
      <ThemedHeaderMod />
      <UserStepFeature isRocketEnabled={isRocketEnabled}>
        <LoaderWrapper>
          <LoaderNew
            isShown={showLoader}
            screenReaderMessage={props.screenReaderLoadingMessage}
          />
        </LoaderWrapper>
        <HideableDiv isHidden={showLoader}>{props.children}</HideableDiv>
      </UserStepFeature>
      <Footer>
        <PrivacyPolicyLink />
      </Footer>
    </UserStepRootDiv>
  );
};
